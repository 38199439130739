<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fa fa-plus"></i> เพิ่มข้อมูลสถานที่ตั้ง </h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body bg-white">
          <validation-observer v-slot="{handleSubmit}" ref="formValidator">
            <b-form @submit.prevent="handleSubmit(addHandler)">
	          <form-location :item="item" />
	          <div class="float-right d-flex">
	            <span><router-link :to="'/location'" class="nav-link text-underline"> กลับ</router-link></span>
	            <!-- <b-button variant="secondary" @click="onBackHandler()"><i class="fa fa-arrow-left"></i> กลับ</b-button> -->
	            <b-button variant="primary" type="submit" class="style_btn" v-if="permission.actionData.flag_insert"><i class="fa fa-check"></i> บันทึก</b-button>
	          </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formLocation from './form.location';
export default {
  name: 'location-view-page-location-add',
  data () {
    return {
      permission:{
        appSlug: 'location',
        actionData: {}
      },
      item: {
        id: '',
        type: 1,
        name: '',
        children: [],
        remark: '',
        address: '',
        city: "",
        subdistrict: "",
        district: "",
        region: 1,
        parent: ''
      }
    }
  },
  methods: {
    async addHandler () {
      const result = await this.HttpServices.postData("/ab_location", this.item);
      if(result&&result.status.code=="200"){
        this.AlertUtils.alertCallback('success', `บันทึกสำเร็จ`, ()=>{
          this.$router.push('/location');
        });
        this.$emit("successFuction", "");
      }else{
        this.AlertUtils.alert('warning', result.status.message);
      }
    },
    onBackHandler(){
      this.$router.push("/location");
    }
  },
  components: {
    formLocation
  },
  async mounted() {
    await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
    this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
  },
}
</script>
